.ml-2{
    margin-left:2rem ;
}
.c-pointer{
 cursor: pointer;
}
.section-padding{
    padding:2rem 0rem;

    @include media-breakpoint-up(md) {
        padding:3rem;
     }

}
