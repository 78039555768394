$anim-time:(
    "1": 1s,
    "2": 2s,
    "3": 3s,
    "4": 4s,
    "5": 5s,
);


@each $anim, $value in $anim-time {
    .translate-fade-#{$anim} {
        animation: $value anim-lineUp ease-out;
    }
    .fade-#{$anim}{
        animation: $value anim-opacity ease-out;
    }
    .fade-last-#{$anim}{
        animation: $value anim-last-opacity ease-out;

    }
    .scale-#{$anim}{
      transition: $anim+'s' ease-out;
      &:hover{
        transform:  scale(1.1);
        img{
          margin-top: 1.1rem;
        }
      }
  }
  @include max-breakpoint(720px){
    .translate-fade-#{$anim} {
      animation: 0s anim-lineUp ease-out;
  }
  .fade-#{$anim}{
      animation: 0s anim-opacity ease-out;
  }
  .fade-last-#{$anim}{
      animation: 0s anim-last-opacity ease-out;

  }
  .scale-#{$anim}{
    transition: $anim+'s' ease-out;
      &:hover{
        transform:  scale(1);
      }
  }
  }
}


/**********************FADE-IN-&-TRANSITION-ANIMATION*********************/


@keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

/**********************FADE-IN-ANIMATION*********************/

@keyframes anim-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes anim-last-opacity {
    0% {
      opacity: 0;
    }
    70%{
        opacity:0;
    }
    100% {
      opacity: 1;
    }
  }

/**********************SCALE-ANIMATION*********************/

  @keyframes anim-scale {
    0% {
      transform: scale(100%);
    }
    30% {
      transform: scale(105%);
    }
    100% {
      transform: scale(100%);
    }
  }



