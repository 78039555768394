/* table of contents
-----------------------------------
-- abstracts imports
   --> variables + mixins .. -> reused items

-- base imports
   --> animation + typography + reset some default html css

-- components imports
   --> to style custom components

-- vendors imports
   --> to override bootsrap css
-----------------------------------
**/


/* abstracts imports */
@import '~bootstrap/scss/bootstrap';

@import './abstracts/variables';
@import './abstracts/mixins';
@import './abstracts/breakpoints';
@import './abstracts/utilities';

@import '@splidejs/splide/css';
@import 'tiny-slider/dist/tiny-slider.css';

/* base imports */
@import './base/reset';
@import './base/typography';
@import './base/animation';

/* vendors imports */
@import './vendors/card';
@import './vendors/button';
@import './vendors/nav';

/* components imports */
@import './components/header-section';
@import './components/scroll-down';
@import './components/footer-section';
@import './components/section';
@import './components/programme-card';
@import './components/part-card';
@import './components/dot-cards';
@import './components/act-card';
@import './components/partner';
@import './components/body-pages';


@import './layouts/details';
