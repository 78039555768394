.programme-section {
    @include section-container($color-purple,
        $font-primary,
        $font-primary,
        $color-white );
}

.partenaires-section {
    @include section-container($color-orange,
        $font-primary,
        $font-primary,
        false);
}

.dot-est-section {
    @include section-container(rgb(249, 249, 245, 0.5),
        #da755e,
        $color-black,
        #e0dece);
}

.actualite-section {
    @include section-container(#3e6d8a,
        $font-primary,
        $font-primary,
        $color-white );
}

.contact-section {
    @include section-container(#f86f56,
        $font-primary,
        $font-primary,
        $color-white );


    .draggable-wrap {
        h3 {
            font-size: 12rem;
        }

        h2 {
            font-size: 5rem;
        }
    }


    @media (min-width: 768px) and (max-width:1024px){
        h2 {
            font-size: 4rem !important;
        }
    }

    @include media-breakpoint-down(sm) {
        .draggable-wrap {
            h3 {
                font-size: 5rem;
            }

            h2 {
                margin-top: -2rem;
                font-size: 1.5rem;
            }
        }
    }
}

.sponsors-section {
    background-color: #f4f4f0;
    padding: 0 3rem;
    margin-bottom: 5.625rem;

    @include media-breakpoint-down(sm) {
        padding: 0 1rem;
    }

    .content-sponsors {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow-x: auto;
    }

    .list-sponsors {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        overflow-x: auto;
    }

    img {
        height: 100px;
        max-width: auto;
        object-fit: contain;
        margin: 1rem;

        max-width: 150px;
        max-height: 70px;
        width: auto;
        height: auto;
        margin: 1rem;
    }

    // img:first-child {
    //     margin-left: 0px;
    // }
    // img:last-child {
    //     margin-right: 0px;
    // }
    @include max-breakpoint(780px) {
        margin-bottom: 2rem;
    }
}

.draggable-wrap {
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;
    max-width: 100vw !important;
    overflow: hidden !important;

    @include max-breakpoint(720px) {
        text-align: left;
    }
}

#base {
    padding: 0rem;
}

#base21 {
    padding: 0rem;
}

#base21 img {
    object-fit: contain !important;
    margin-top: 10px !important;
    width: 20%;
    margin: auto;
    height: 120px;
}

#base21 strong {
    width: 70%;
}

#base21 a {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.program-slider {
    .tns-outer {
        position: relative;
    }

    .tns-controls {
        position: absolute;
        top: 40%;
        width: 100%;
        height: 50px;

        // @include media-breakpoint-down(sm) {
        //     display: none;
        // }

        button {
            align-items: center;
            background: #ccc;
            border: 0;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            height: 2em;
            justify-content: center;
            opacity: 0.7;
            padding: 0;
            position: absolute;

            width: 2em;
            z-index: 1;

            &::before {
                content: "";
                border: solid black;
                border-width: 0 3px 3px 0;
                display: inline-block;
                padding: 3px;
            }

            &:hover {
                opacity: 0.9;
            }
        }

        button[data-controls="prev"] {
            left: 1.5rem;

            &::before {
                transform: rotate(135deg);
            }
        }

        button[data-controls="next"] {
            right: 1.5rem;

            &::before {
                transform: rotate(-45deg);
            }
        }
    }
}


.program-slider-base-22 {


    .tns-controls {

        button[data-controls="prev"] {
            left: -1rem;

            &::before {
                transform: rotate(135deg);
            }
        }

        button[data-controls="next"] {
            right: -0.3rem;

            &::before {
                transform: rotate(-45deg);
            }
        }
    }
}


.theme-section {
    p {
        font-size: 1.4rem;

        @include media-breakpoint-down(sm) {
            font-size: 1.2rem;
        }
    }

    &.theme-section-purple {
        background-color: $color-purple;
    }

    &.theme-section-orange {
        background-color: $color-orange;

        .draggable-wrap {
            h3 {
                color: transparent;
                -webkit-text-stroke: 2px #fff;
                opacity: 0.25;
            }

            h2 {
                color: #fff;
            }
        }
    }

    &.theme-section-orange-light {
        background-color: $color-orange-light;
    }

    &.theme-section-light-grey {
        background-color: $color-light-grey;
    }

    &.theme-section-light-orange {
        background-color: $color-orange-light;
    }

    &.theme-section-blue {
        background-color: $color-blue;
    }

    &.theme-section-green {
        background-color: $color-green;
    }

    &.theme-section-grey {
        background-color: $color-grey;
    }

    &.theme-section-white {
        background-color: $color-white;

        .draggable-wrap {
            h3 {
                color: transparent;
                -webkit-text-stroke: 2px #e0dece;
                opacity: 0.25;
            }

            h2 {
                color: #da755e;
            }
        }
    }
}
