$base-font-size: 2.5rem !default;
$font-size-sm: $base-font-size * 0.75 !default;
$font-size-lg: $base-font-size * 1.5 !default;
$font-size-xl: $base-font-size * 2 !default;
$font-size-xxl: $base-font-size * 3 !default;

$font-primary: white;

$color-white-100:rgb(255, 255, 255,0.1);
$color-white-200:rgb(255, 255, 255,0.2);
$color-white-300:rgb(255, 255, 255,0.3);
$color-black-100:rgb(0, 0, 0,0.1);
$color-black-200:rgb(0, 0, 0,0.2);
$color-black-300:rgb(0, 0, 0,0.3);

$color-brick: #C94129;
$color-brown: #DA755E;
$color-brick-700: rgb(201, 65, 41,0.75);

$color-purple: #9488C0;
$color-orange: #F86F56;
$color-grey: #909090;
$color-light-grey: #F4F4F0;
$color-white: #FFFFFF;
$color-black: #000;
$color-purple-light: rgb(148, 136, 192,0.15);
$color-orange-light: rgba(248, 108, 84, 0.15);
$color-blue: #3E6D8A !default;
$color-grey: #828282 !default;
$color-green: #7BCAAE !default;
$color-green-light: #E4F5EF !default;
