@mixin stroke-text($color) {
    color: transparent;
    -webkit-text-stroke: 2px $color;
}

@mixin dot-card($font-color, $bg-color) {
    padding: 2.5rem 1.25rem 1.25rem 1.25rem;
    flex: 1 0 21%;
    margin: 1rem;
    min-width: 15.75rem;
    color: $bg-color;
    font-size: 1.875rem;
    img {
        width: 7.8rem;
        height: auto;
        margin-bottom: 1.5rem;
    }
    h3 {
        font-size: 1.875rem;
        padding: 0;
    }
    background-color: $font-color;

    @include max-breakpoint(1020px) {
        min-width: 13.75rem;
    }
    @include max-breakpoint(550px) {
        text-align: center;
        img {
            margin: auto;
        }
    }
    @include max-breakpoint(380px) {
        flex: 1 0 100%;
    }
}

@mixin section-container($color, $title-color, $sub-color, $stock) {
    width: 100%;
    background-color: $color;
    .draggable-wrap {
        h3 {
            text-transform: uppercase;
            font-size: 15rem;
            color: $title-color;
            opacity: 0.25;
            @include media-breakpoint-down(md) {
                font-size: 3rem;
                margin-top: 2rem;
                line-height: 2rem;
            }
            @if $stock {
                @include stroke-text($stock);
            }
        }
        h2 {
            text-transform: capitalize;
            font-size: 9rem;
            margin-top: -12rem;
            line-height: 14rem;
            z-index: 9;
            color: $title-color;
            text-align: left;

            @include media-breakpoint-down(md) {
                margin-top: -2rem;
                font-size: 3rem;
                line-height: 5rem;;
            }
        }
    }
    p {
        font-size: 1.5rem;
        color: $sub-color;
        // margin-top: -2rem;
        // margin-bottom: 4rem;
        letter-spacing: 0px !important;
        font-stretch: 2.1875rem !important;
    }

    &.theme-section-white p {
        color: #000;
    }

    &.theme-section-orange h6 {
        color: $sub-color;
    }


}
