
$first-dot-color:#9B7287;
$first-dot-bg:#E7DCDE;

$second-dot-color:#7BCAAE;
$second-dot-bg:#E4F5EF;

$third-dot-color:#3E6D8A;
$third-dot-bg:#E7E8E9;

$fourth-dot-color:#E09E90;
$fourth-dot-bg:#F5E5E0;

.dot-card-first{
  @include dot-card($first-dot-bg,$first-dot-color);
    // margin-left: 0px;
}
.dot-card-second{
    @include dot-card($second-dot-bg,$second-dot-color)
  }
  .dot-card-third{
    @include dot-card($third-dot-bg,$third-dot-color)
  }
  .dot-card-fourth{
    @include dot-card($fourth-dot-bg,$fourth-dot-color);
    // margin-right: 0;
  }
