footer {
    h3 {
        text-transform: uppercase;
        font-size: 12rem;
        opacity: 0.15;
    }
    h2 {
        font-size: 7.8125rem;
        margin-top: -7rem;

        @include media-breakpoint-down(md) {
            font-size: 2.5rem;
            margin-top: 0;
        }
    }
    h6 {
        padding-top: 1.5rem;
        font-size: 1.25rem;
        font-weight: 400;
    }

    /*
        @include max-breakpoint(1650px){
            h3{
                font-size: 13rem;
            }
            h2{
                font-size: 6.8rem;
            }
            @include max-breakpoint(1050px){
                h3{
                    font-size: 12rem;
                }
                h2{
                    font-size: 6rem;
                }
            }

            @include max-breakpoint(600px){
                h3{
                    font-size: 10rem;
                }
                h2{
                    font-size: 5.5rem;
                    margin-top: -10.75rem;
                }
            }


    }
    */
    .footer-form {
        width: 100%;
        margin-top: 3rem;
        input {
            width: 100%;
            max-width: 500px;
            background-color: transparent;
            padding: 1.25rem;
            border: solid 2px $color-white;
            border-radius: 5px;
            margin-right: 3rem;
            margin-bottom: 2rem;
            @include media-breakpoint-down(md) {
                margin-right: 0;
            }

            &:focus {
                outline: none;
            }
            &::placeholder {
                color: $color-white;
                font-size: 1.5625rem;
            }
            //   @include max-breakpoint(780px){
            //     width: 100%;
            //     margin-bottom: 2rem;
            //     margin-right: 0;
            //   }
        }
        // @include max-breakpoint(1050px){
        //     width: 80%;
        //     margin-bottom: 3rem;

        //     input::placeholder{
        //         font-size: 1rem;

        //     }

        //   }

        //   @include max-breakpoint(600px){
        //     width: 90%;

        //   }
    }
    .footer-question {
        width: calc(100% - 370px);

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
    // .footer-flex-container{
    //     max-height: 4.375rem;
    // }
    .footer-right-section {
        width: 370px;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        h6 {
            padding: 0;
            text-align: center;
        }
    }
    .footer-logo {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        /*
        width: 16rem;
        min-width: 12.5rem;
        height: auto;
        margin-top: 3rem;
        */

        // @include max-breakpoint(980px){
        //     margin-top: 5rem;
        //   }
    }
    .footer-socials {
        padding-top: 10px;
        padding-bottom: 10px;
        img {
            height: 1.875rem;
            margin: 0 1.5625rem 0 1.5625rem;
            width: auto;
        }
    }
}

footer.main-footer {
    background-color: $color-white;
    &.footer-transparant {
        background-color: #{$color-white}60;
    }
    .footer-logo {
        > img {
            width: 83px;
            @include media-breakpoint-up(md) {
                margin-right: 40px;
            }
        }
    }
    .footer-right-section {
        width: 100%;
        .footer-bottom {
            .f-item {
                display: flex;
                justify-content: flex-start;

                @include media-breakpoint-down(md) {
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                }

                li {
                    a,
                    a:focus,
                    a:active {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: rgba($color-black, 0.7);
                        margin-bottom: 10px;
                    }
                    a:hover {
                        color: $color-black;
                    }
                }

                &.footer-menu {
                    @include media-breakpoint-up(lg) {
                        justify-content: right;
                    }
                    li {
                        margin-left: 2rem;
                    }
                }
            }
        }
    }
    .footer-top {
        position: relative;
        margin-bottom: 1rem;
        /*padding: 3rem 0;*/
        &:after {
            content: "";
            width: 100%;
            height: 2px;
            background-color: rgba($color-black, 0.2);
            position: absolute;
            bottom: 0;
        }
        .footer-data {
            margin: 1rem 0;
            @include media-breakpoint-up(lg) {
                margin: 2rem 0;
            }
            > span {
                display: block;
                color: $color-black;
                font-weight: 600;
                font-size: 23px;
                &.address {
                    font-weight: 400;
                    font-size: 16px;
                }
            }
        }
        .footer-socials {
            display: flex;
            justify-content: center;
            padding-right: 1rem;
            a {
                margin-left: 2rem;
            }
            @include media-breakpoint-up(lg) {
                justify-content: right;
            }
        }
    }
}
.d-icon {
    width: 28px;
    height: 28px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &.d-icon-facebook {
        background-image: url(../../img/icons/facebook-black.svg);
    }
    &.d-icon-youtube {
        background-image: url(../../img/icons/youtube-black.svg);
    }
    &.d-icon-linkedin {
        background-image: url(../../img/icons/linkedin-black.svg);
    }
    &.d-icon-external-link {
        background-image: url(../../img/icons/external-link.svg);
    }
}
