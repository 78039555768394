*,
*::before,
*::after {
    box-sizing: border-box;
}
html {
    font-size: 14px;
    @include media-breakpoint-up(lg) {
        font-size: 16px;
    }
    // @include media-breakpoint-up(xl) {
    //     font-size: 17px;
    // }
    // @include media-breakpoint-up(xxl) {
    //     font-size: 18px;
    // }
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

html:focus-within {
    scroll-behavior: smooth;
}

body {
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}
ul {
    padding: 0;
    list-style-type: none;
}

a {
    font-size: 1.25rem;
    text-decoration: none;
    color: $font-primary;
    transition: all ease 0.5s;

}
img,
picture {
    max-width: 100%;
    display: block;
}

input,
button,
textarea,
select {
    font: inherit;
    &:focus-visible {
        outline: none;
    }
    &::placeholder {
        opacity: 1;
        color: white;
    }
}

@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

img {
    image-rendering: -webkit-optimize-contrast;
}

/*--------Responsive Rem---------*/
// @include xl {
//   body{
//     font-size: 16px;
//   }
// }

// @include xs {
//   html{
//     font-size: 8px !important;
//   }
// }

// @include md {
//   html{
//     font-size: 10px !important;
//   }
// }

// @include lg {
//   html{
//     font-size: 13px !important;
//   }
//   }

// @include xl {
//   html{
//     font-size: 15px !important;
//   }
// }
// @include min-breakpoint(1400px){
//   html{
//     font-size: 16px !important;
//   }
// }
