.partenaires-card {
    color: $font-primary;
    padding: 1rem;
    // margin: 1rem 0;
    // width: 25%;
    text-align: left;
    height: 100%;
    overflow: hidden;

    img {
        width: 6.25rem;
        height: auto;
        margin: auto;
        padding-bottom: 1rem;
    }
    h3 {
        text-align: center;
    }

    .part-block-text {
        padding-left: 1rem;
        h3 {
            text-align: left;
            font-size: 2.1875rem;
            @include media-breakpoint-down(sm) {
                font-size: 1.8rem;
            }
        }

        p {
            padding: 1rem 0 0 0;
            font-weight: 300;
            font-size: 1rem;
            line-height: 1.5rem;
            margin: 0;
            @include media-breakpoint-down(sm) {
                font-size: 1.2rem;
            }
        }
    }

    &.card-purple {
        // background-color: $color-purple;

        color: #9b7287;
        background-color: #e7dcde;
    }
    &.card-orange {
        // background-color: $color-orange;
        background-color: rgba(201, 65, 41, 0.75);
        color: white;
    }
    &.card-orange-light {
        // background-color: $color-orange-light;
        color: #e09e90;
        background-color: #f5e5e0;
    }
    &.card-light-orange {
        // background-color: $color-orange-light;
        color: #e09e90;
        background-color: #f5e5e0;
    }
    &.card-blue {
        // background-color: $color-blue;
        color: #3e6d8a;
        background-color: #e7e8e9;
    }
    &.card-green {
        // background-color: $color-green;
        color: #7bcaae;
        background-color: #e4f5ef;
    }
    &.card-grey {
        background-color: $color-grey;
    }
    &.card-white {
        background-color: $color-white;
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}
