.btn{
    padding:10px 3.125rem;
    font-size: 1.25rem;
    font-weight: 500;

    &:hover {
        background-color: #f8f9fa;
        border-color: #f8f9fa;
        color: #d54d35;
    }

}
.btn-orange{
    color: $color-orange;
}

.btn-footer{
    max-height: 70px;
    padding-left: 5.625rem;
    padding-right: 5.625rem;
    margin-right: 0rem;


}
