/*----Header-Text----*/
header {
    color: $font-primary;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    padding-top: 0;
    transition: all ease 0.5s;

    @include media-breakpoint-up(lg) {
        padding-top: 1rem;
    }

    &.bg-nav-btn {
        background-color: rgba(0, 0, 0, 0.7);
        // border-radius: 0px 0px 15px 15px;
        // border-bottom: 2px solid rgba(255, 255, 255, 0.2);
        @include media-breakpoint-up(lg) {
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    /*-----Nav-Bar----*/

    .navbar {
        .navbar-toggler {
            background-color: rgba(0, 0, 0, 0.6);
            border-radius: 15%;
            // font-size: 26px;
            padding: 5px;
            margin-right: 1rem;

            &:focus {
                box-shadow: none;
            }
        }

        .nav-item {
            position: relative;
            margin-right: 0.6rem;
            margin-left: 0.6rem;

            // @include media-breakpoint-up(xl) {
            //     margin-right: 0.1rem;
            //     margin-left: 0;
            // }

            // @include media-breakpoint-up(xxl) {
            //     margin-right: 0.6rem;
            //     margin-left: 0.6rem;
            // }
            &.dropdown:after {
                content: "";
                display: inline-block;
                margin-left: 0.255em;
                vertical-align: 0.255em;
                border-top: 0.3em solid;
                border-right: 0.3em solid transparent;
                border-bottom: 0;
                border-left: 0.3em solid transparent;
                position: absolute;
                right: -7px;
                top: 15px;
            }

            .nav-link,
            .nav-link:hover,
            .nav-link:focus,
            .nav-link:active {
                color: $color-white;
                text-transform: uppercase;
                font-size: 1rem;
                font-weight: 500;
                text-align: center;

                &::before {
                    background: $color-white;
                }
                @include media-breakpoint-up(xxl) {
                    font-size: 1.2rem;
                    font-weight: 600;
                }
            }
        }

        ul.navbar-nav {
            li {
                text-align: center;
                //     margin-left: 0.1rem;
                // @include media-breakpoint-up(xxl) {
                //     margin-left: 0.6rem;
                // }

                &:last-child {
                    margin-right: 3rem;
                    @include media-breakpoint-down(xl) {
                        margin: auto;
                    }
                }

                a {
                    display: inline-block;
                    position: relative;

                    &::before {
                        content: "";
                        width: 100%;
                        height: 2px;
                        background: $color-black;
                        right: 0px;
                        left: 0;
                        bottom: -2px;
                        position: absolute;
                        opacity: 0;
                        z-index: 2;
                        transform: scaleX(0);
                        transition: all 0.3s ease-in-out 0s;
                    }

                    &:after {
                        display: none;
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        &::before {
                            opacity: 1;
                            transform: scaleX(1);
                        }
                    }
                }
            }

            & > .dropdown {
                position: relative;

                ul.dropdown-menu {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    opacity: 0;
                    visibility: hidden;
                    z-index: 100;
                    //
                    padding: 0.5rem 1rem;
                    border-radius: 0;
                    box-shadow: 0 0 3px 0 rgb(0, 0, 0, 10%);
                    background-color: #fff;
                    // min-width: 250px;
                    margin: 0;
                    border: none;
                    transform: translateY(20px);
                    transition: all 0.3s;

                    @include media-breakpoint-up(xl) {
                        display: block !important;
                    }

                    @include media-breakpoint-down(xl) {
                        position: relative;
                        top: 0;
                        background: transparent;
                        text-align: center;
                        // color: #fff;
                        font-size: 15px;
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                        height: 0;
                        display: none;
                    }

                    li {
                        margin: 1rem;
                        width: auto;
                        min-width: auto;
                        margin: 0;
                        padding: 0;
                        white-space: nowrap;
                        text-align: left;
                        transition: all 0.15s ease-in-out;

                        > a {
                            color: $color-black;
                            position: relative;

                            padding: var(--bs-nav-link-padding-y)
                                var(--bs-nav-link-padding-x);
                            font-size: var(--bs-nav-link-font-size);
                            font-weight: var(--bs-nav-link-font-weight);
                            transition: all 0.15s ease-in-out;

                            &:hover,
                            &:focus,
                            &:active {
                                color: $color-black;
                                &::before {
                                    background: $color-black;
                                }
                            }

                            @include media-breakpoint-down(lg) {
                                color: $color-white;
                                &:hover,
                                &:focus,
                                &:active {
                                    color: $color-white;
                                    &::before {
                                        background: $color-white;
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover {
                    ul.dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                        // height: auto;
                        // display: block;
                    }
                }

                &.hover {
                    ul.dropdown-menu {
                        @include media-breakpoint-down(xl) {
                            height: auto;
                            display: block;
                            // display: block;
                        }
                    }
                }
            }

            &.lang-selector {
                // margin-right: 2rem;
                // @include media-breakpoint-down(xl) {
                //     margin-right: 0;
                // }
                .nav-item {
                    .nav-link {
                        opacity: 0.6;
                        position: relative;
                        padding: var(--bs-nav-link-padding-y);

                        &:not(:last-child) {
                            &::after {
                                content: "";
                                width: 2px;
                                height: 75%;
                                position: absolute;
                                right: 0;
                                background-color: $color-white;
                                display: inline-block;
                            }
                        }

                        &.active {
                            opacity: 1;
                            // &:after {
                            //     background: none repeat scroll 0 0 transparent;
                            //     bottom: 0;
                            //     content: "";
                            //     display: block;
                            //     height: 2px;
                            //     left: 50%;
                            //     position: absolute;
                            //     background: $color-white;
                            //     transition: width 0.3s ease 0s,
                            //         left 0.3s ease 0s;
                            //     width: 0;
                            //     &:hover {
                            //         width: 100%;
                            //         left: 0;
                            //     }
                            // }
                        }
                    }
                }
            }
        }
    }

    .dot-logo-mini {
        margin-left: 1rem;
        transition: all ease 0.5s;
        width: 56px;

        @include media-breakpoint-up(xl) {
            width: 112px;
        }
    }

    &.fix-header {
        color: $color-black;
        background-color: $color-white;
        padding-top: 0;
        box-shadow: 0px 0px 7px rgba(0, 0, 0, 15%);

        ul.navbar-nav.lang-selector
            .nav-item
            .nav-link:not(:last-child)::before,
        .nav-link:not(:last-child)::after {
            background-color: $color-black;
        }
        ul.navbar-nav.lang-selector .nav-item .nav-link.active::after {
            background-color: $color-black;
        }

        .dot-logo-mini {
            margin-top: 0rem !important;
            width: 56px !important;
        }
        .nav-item {
            .nav-link,
            .nav-link:hover,
            .nav-link:focus,
            .nav-link:active {
                color: $color-black;
                &::before {
                    background: $color-black;
                }
            }
        }

        .navbar-brand {
            position: absolute;
            top: 50%;
            left: 1.5rem;
            transform: translate(-0.75rem, -50%);

            img {
                width: 50px;
                transition: all ease 1s;
            }

            @include media-breakpoint-down(md) {
                position: relative;
                top: 0;
                transform: none;
            }
        }

        & .navbar ul.navbar-nav > .dropdown ul.dropdown-menu li > a {
            @include media-breakpoint-down(lg) {
                color: $color-black;
                &:hover,
                &:focus,
                &:active {
                    color: $color-black;
                    &::before {
                        background: $color-black;
                    }
                }
            }
        }

        .navbar-nav {
            margin-top: 0.5rem !important;
            margin-bottom: 0.5rem !important;
        }
    }
}

.alert-content {
    position: absolute;
    width: 50%;
    top: 10%;
    z-index: 9999999999999;
    left: 50%;
    transform: translateX(-50%);
}

section.hero {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;

    .slide-hero-item {
        height: 100%;
    }

    video {
        position: relative;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }

    .hero-content {
        position: absolute;
        top: 0;
        background: rgb(155, 114, 135, 0.75) 0% 0% no-repeat padding-box;
        color: #f1f1f1;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        flex-flow: column;
        justify-content: center;

        padding-top: 13rem;
        @include media-breakpoint-down(lg) {
        }
        @include media-breakpoint-down(md) {
        }
        padding-bottom: 3rem;
    }

    .hero-sub-text-block {
        padding: 2rem 1rem;
        display: flex;
        justify-content: space-between;
        width: 100%;

        h3 {
            font-size: 4rem;
            opacity: 0.25;
            padding: auto;

            @include media-breakpoint-down(xl) {
                font-size: 3rem;
            }

            @include media-breakpoint-down(sm) {
                font-size: 2rem;
            }
        }
    }

    .hero-text-block {
        text-transform: uppercase;
        font-size: $base-font-size;
        letter-spacing: 0px;
        padding-left: 1rem;
        width: 100%;

        .hero-text-block-first {
            letter-spacing: 0px;
            font-size: 2rem;
            line-height: 3rem;
            font-weight: normal;
        }

        .hero-text-block-second {
            letter-spacing: 0px;
            font-size: 2.5rem;
            line-height: 3rem;
            font-weight: 600;
        }

        .hero-text-block-third {
            color: $color-white;
            letter-spacing: 0px;
            font-size: 6rem;
            line-height: 8rem;
            font-weight: bold;
        }

        @include media-breakpoint-up(sm) {
            .hero-text-block-first {
                font-size: 2rem;
                line-height: 3rem;
            }

            .hero-text-block-second {
                font-size: 3rem;
                line-height: 4rem;
            }

            .hero-text-block-third {
                font-size: 7rem;
                line-height: 9rem;
            }
        }

        @include media-breakpoint-up(md) {
            .hero-text-block-first {
                font-size: 2rem;
                line-height: 3rem;
            }

            .hero-text-block-second {
                font-size: 4rem;
                line-height: 6rem;
            }

            .hero-text-block-third {
                font-size: 10rem;
                line-height: 12rem;
            }
        }

        @include media-breakpoint-up(xl) {
            .hero-text-block-first {
                font-size: 3rem;
                line-height: 3.45rem;
            }

            .hero-text-block-second {
                font-size: 5.25rem;
                line-height: 9.5rem;
            }

            .hero-text-block-third {
                font-size: 13rem;
                line-height: 14rem;
            }
        }

        @include media-breakpoint-up(xxl) {
            .hero-text-block-first {
                font-size: 3.45rem;
                line-height: 3.45rem;
            }

            .hero-text-block-second {
                font-size: 6.25rem;
                line-height: 9.5rem;
            }

            .hero-text-block-third {
                font-size: 16rem;
                line-height: 14rem;
            }
        }

        @media screen and (max-height: 700px) {
            .hero-text-block-first {
                line-height: 2.45rem;
            }

            .hero-text-block-second {
                line-height: 4.5rem;
            }

            .hero-text-block-third {
                line-height: 9rem;
            }
        }

        /*

        // @include max-breakpoint(480px) {
        //     //     padding-left: 1rem;
        //     //     padding-top: 25rem;
        //     .hero-text-block-third {
        //         font: normal normal bold 6rem/6rem Poppins;
        //     }
        //     .hero-text-block-second {
        //         font: normal normal 600 calc(4.7rem * 0.75) / 6rem Poppins;
        //     }
        //     .hero-text-block-first {
        //         font: normal normal normal calc(2.5rem * 0.75) / 0 Poppins;
        //     }
        // }

        // @include max-breakpoint(330px) {
        //     //     padding-left: 1rem;
        //     //     padding-top: 25rem;
        //     .hero-text-block-third {
        //         font: normal normal bold calc(12rem * 0.6) / 6rem Poppins;
        //     }
        //     .hero-text-block-second {
        //         font: normal normal 600 calc(4.7rem * 0.6) / 6rem Poppins;
        //     }
        //     .hero-text-block-first {
        //         font: normal normal normal calc(2.5rem * 0.6) / 0 Poppins;
        //     }
        // }

        @include sm {
            //     padding-left: 3.125rem;
            //     padding-top: 30rem;
            .hero-text-block-third {
                font: normal normal bold 12rem/14rem Poppins;
            }
            .hero-text-block-second {
                font: normal normal 600 4.7rem/9.5rem Poppins;
            }
            .hero-text-block-first {
                font: normal normal normal 2.5rem/0 Poppins;
            }
        }
        @include md {
            //     padding-top: 25rem;
            .hero-text-block-third {
                font: normal normal bold 15rem/14rem Poppins;
            }
            .hero-text-block-second {
                font: normal normal 600 6.25rem/9.5rem Poppins;
            }
            .hero-text-block-first {
                font: normal normal normal 3.45rem/0 Poppins;
            }
        }

        @include lg {
            //     padding-top: 9.375rem;
            .hero-text-block-third {
                font: normal normal bold 16rem/14rem Poppins;
            }
            .hero-text-block-second {
                font: normal normal 600 6.25rem/9.5rem Poppins;
            }
            .hero-text-block-first {
                font: normal normal normal 3.45rem/0 Poppins;
            }
        }
        @media (max-height: 650px) {
            //     padding-top: 5rem !important;
            .hero-text-block-third {
                font: normal normal bold 12rem/6rem Poppins;
            }
            .hero-text-block-second {
                font: normal normal 600 4.7rem/9rem Poppins;
            }
            .hero-text-block-first {
                font: normal normal normal 2.5rem/0 Poppins;
            }
        }

        */

        // @media (max-height: 400px) {
        //     padding-top: 3rem !important;
        // }
    }
}
