

body {
    font-family: 'Poppins', sans-serif;

  }


  /*

  h1 {
    font-size: 3.5rem;
    line-height: 1.4;
  }

  h2 {
    font-size: 2.75rem;
    line-height: 1.25;
  }

  h3 {
    font-size: 2.25rem;
  }

  h4 {
    font-size: 1.65rem;
  }

  p {
    font-size: 1.20rem;
    margin-bottom: 1.25rem;
  }
  .small {
    font-size: .95rem;
  }

  */
