.act-card {
    background-color: $color-white;
    color: $color-grey;
    height: auto !important;


    img {
        width: 100%;
        height: 320px;
        object-fit: cover;
    }
    h6 {
        font-size: 1.2rem;
    }
    h5 {
        padding-top: 0.75rem;
        color: $color-black;
        font-size: 1.5rem;
        height: 63px;

        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
    }
    p {
        margin: 0;
        padding: 0;
        color: $color-grey;
        font-size: 1.2rem;
        line-height: 1.4em;
        height: 94px;

        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            height: 108px;
        }
    }
}
.splide-center {
    margin: auto;
}

// .splide__arrow {
//     @include media-breakpoint-down(sm) {
//         display: none;
//     }
// }



.btn-center {
    // @include max-breakpoint(780px){
    //     width: 50%;
    //     display: flex;
    //     justify-content: center;
    // }
}
