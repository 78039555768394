.details-layout {
    // header {
    //     height: auto;
    //     .header-content {
    //         // background: none;
    //         height: auto;
    //     }
    // }
    .draggable-wrap {
        max-width: 100% !important;
    }

    .second-section {
        padding: 3rem;
        overflow: hidden;
        position: relative;
        // z-index: 22;
        // margin-top: -8rem;

        .theme-section {
            padding: 3rem !important;
        }
    }

    .theme-section {
        color: $color-white;
        padding: 15rem 3rem 3rem;
        overflow: hidden;

        @include media-breakpoint-down(lg) {
            padding: 8rem 1rem 3rem;
        }

        &.theme-section-purple {
            background-color: $color-purple;
        }

        &.theme-section-orange {
            background-color: $color-orange;
        }

        &.theme-section-orange-light {
            background-color: $color-orange-light;
        }

        &.theme-section-light-orange {
            background-color: $color-orange-light;
        }

        &.theme-section-blue {
            background-color: $color-blue;
        }

        &.theme-section-green {
            background-color: $color-green;
        }

        &.theme-section-grey {
            background-color: $color-grey;
        }

        &.theme-section-white {
            background-color: $color-white;
        }

        .title-content {
            font-weight: 600;
            margin-bottom: 2rem;

            .pre-title {
                color: transparent;
                margin-left: 2px;
                -webkit-text-stroke: 2px $color-white;
                opacity: 0.25;
                font-size: 70px;
                white-space: nowrap;
                overflow: hidden;

                @include media-breakpoint-down(md) {
                    -webkit-text-stroke: 1px $color-white;
                    font-size: 27px;
                }
            }

            .title {
                font-size: 90px;
                margin-top: -3rem;

                @include media-breakpoint-down(md) {
                    font-size: 30px;
                    margin-top: -1rem;
                }
            }
        }

        .info-content {
            margin-bottom: 3rem;
            // p {
            //     font-size: 2.5rem;
            //     @include media-breakpoint-down(xxl) {
            //         font-size: 30px;
            //     }
            // }

            ul {
                list-style-type: disc;
                padding-left: 1rem;
            }

            h2 {
                font-size: 2.5rem;

                /*position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 60%;*/
                @include media-breakpoint-down(md) {
                    font-size: 1.875rem;
                }
            }

            img {
                width: 100%;
            }
        }

        .bloc-content {
            h2,
            h3,
            h4 {
                color: $color-black;
                font-weight: 600;
                font-size: 2.5rem;
            }

            ul {
                margin: 1rem 1.5rem;
                list-style: disc;

                li {
                    // color: $color-blue;
                    // font-weight: 600;
                    // font-size: 30px;
                }
            }

            .boxs-content {
                ul {
                    list-style: none;
                }
            }
        }

        .boxs-content {
            /*position: absolute;
            top: -80px;
            left: 5%;
            padding-right: 5%;*/
            // padding: 0 25%;
            // margin-top: -6rem;
            .box-item {
                // margin-bottom: 1rem;
                height: 100%;
                box-shadow: 0px 0px 6px rgb(0, 0, 0, 0.25);
                background-color: $color-white;
                padding: 15px;
                border-radius: 20px;
                transition: all 0.5s ease;

                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                &:hover {
                    box-shadow: 0px 0px 16px rgb(0, 0, 0, 0.25);
                }

                .box-img {
                    width: 230px;
                    height: 230px;
                    max-width: 100%;
                    background: #fff;
                    padding: 0;
                    border-radius: 1000px;
                    overflow: hidden;
                    margin: 0 auto;
                    transition: all 0.2s ease-in-out;
                    margin-bottom: 5px;

                    // &:hover {
                    //     background: #9488c0;
                    // }

                    img {
                        height: auto;
                        object-fit: cover;
                        overflow: hidden;
                        width: 340px;
                        object-position: center;
                        max-width: initial;
                        margin-left: -50px;
                        filter: grayscale(100%);
                    }
                }

                .box-title {
                    color: $color-black;
                    font-size: 23px;
                    font-weight: 600;
                    text-align: center;
                }

                .box-description {
                    color: $color-grey;
                    font-size: 1rem;
                    font-weight: 400;
                    text-align: center;
                    margin-top: 1rem;

                    img {
                        height: 90px;
                        margin: 0 auto;
                        object-fit: contain;
                        object-position: center;
                        width: 180px;
                    }
                }
            }
        }
    }
}

.sectioncontact {
    padding: 3rem;
}

input,
textarea,
select {
    width: 100%;
    max-width: 500px;
    height: 70px;
    border: 2px solid #ffffff;
    border-radius: 5px;
    background: none;
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 15px;
    font-size: 1.25rem;
    color: #ffffff;

    option {
        background: rgba(0, 0, 0, 0.3);
        color: #fff;
    }

    @include media-breakpoint-down(sm) {
        margin-right: 0;
    }
}

.formcontact button {
    max-width: 500px;
    width: 100%;
    margin-bottom: 1rem;
}

.btn_discover {
    // margin-top: 50px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    color: #f86f56;
    font: normal normal medium 25px/38px Poppins;
    letter-spacing: 0px;
    border-style: none;
    padding: 1rem 2rem;
    margin: 1rem 0;
}

section .table {
    thead {
        tr {
            background: transparent;
            width: 100%;

            th {
                color: #505050;
                border: none;

                padding: 1rem;
                vertical-align: middle;
                font-size: 1.2rem;
                text-align: center;
            }
        }
    }

    tbody {
        tr {
            color: black;
            background: white;
            margin-bottom: 1rem;
            display: table;
            width: 100%;
            border-radius: 5px;
            border: 0;
            border-collapse: unset;

            td {
                padding: 0.5rem;
                vertical-align: middle;
                text-align: left;
                border: none;

                img {
                    max-width: 100%;
                    border: none;
                    box-shadow: none;
                    border-radius: 999px;
                    box-sizing: content-box;
                    object-fit: contain;
                    background-color: white;
                    padding: 10px;
                    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }
}

.pop_up_label {
    color: black;
}

.team_members,
.programs,
.DigitalTransformationCenter,
.bloc-content {
    padding-top: 3rem;
}

.section-padding.pre-sponsors {
    padding: 2rem 0rem;

    @include media-breakpoint-up(md) {
        padding: 0 3rem;
    }

    p {
        font-size: 1.4rem;
        color: $color-black;
        letter-spacing: 0px !important;
        font-stretch: 2.1875rem !important;
        // @include media-breakpoint-up(md) {
        //     font-size: 1.2rem;
        // }
    }
}

.responsive-table {
    margin: 0;

    li {
        border-radius: 3px;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
    }

    .table-header {
        background-color: #95a5a6;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        color: $color-black;
        font-weight: 600;
    }

    .table-row {
        background-color: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
        color: $color-black;
    }

    .t-col {
        margin-right: 1rem;
        align-items: center;
        display: flex;

        &:last-child {
            margin-right: 0;
        }
    }

    .t-col-1 {
        flex-basis: 10%;
        text-align: center;

        img {
            max-width: 100%;
            border: none;
            box-shadow: none;
            // border-radius: 999px;
            box-sizing: content-box;
            object-fit: contain;
            background-color: white;
            padding: 10px;
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
            width: 80px;
            height: 80px;
            margin: auto;
        }
    }

    .t-col-2 {
        flex-basis: 15%;
        font-weight: 600;
    }

    .t-col-3 {
        flex-basis: 40%;
    }

    .t-col-4 {
        flex-basis: 25%;
    }

    .t-col-5 {
        flex-basis: 10%;
        text-align: center;
        justify-content: center;
    }

    @media all and (max-width: 767px) {
        .table-header {
            display: none;
        }

        .table-row {
        }

        li {
            display: block;
        }

        .t-col {
            flex-basis: 100%;
        }

        .t-col {
            display: flex;
            padding: 10px 0;
            flex-direction: column;
            width: 100%;

            &:before {
                padding-bottom: 15px;
                content: attr(data-label);
                flex-basis: 100%;
                text-align: center;
                display: block;
                width: 100%;
                font-weight: 600;
                color: #95a5a6;
                font-size: 1rem;
            }
        }
    }
}

.body-theme-orange {
    .table-header {
        background-color: rgba(201, 65, 41, 0.75);
    }
}

.body-theme-green {
    .table-header {
        background-color: #69ad95;
    }
}

.modal {
    color: $color-black;

    .modal-title {
        font-size: 1.5rem;
        font-weight: 600;
    }

    .modal-body {
        font-size: 1rem;
        text-align: center;
        font-weight: 600;
    }
}

.search-container {
    // padding: 5em;
    height: 74px;
    flex-wrap: nowrap;
    background-color: white;
    padding: 5px;
    display: flex;
    border-radius: 40px;
    width: auto;
}

.input-search-zone {
    margin-bottom: 0;
    width: 70%;
}

.select-search-zone {
    margin-right: 15px;

    max-width: auto;
}

.tap_input {
    background-color: transparent;
    max-width: 100%;
    border: none;
    outline: none !important;
    color: gray;
}

.tap_input::placeholder {
    color: black;
}

.tap_select {
    outline: none !important;
    border: none !important;
    background-color: transparent;
    color: black;
    text-align: center;
}

.custom-select-partner {
    // background-color: white;
    padding: 15px;
    font-size: 1.25rem;
    color: black;
    border: none;
    width: 350px;

    @include media-breakpoint-down(sm) {
        width: 149px;
        margin-left: -9px;
    }
}

.iso-container {
    display: flex;
    justify-content: center;
    align-items: center;
    //  flex-wrap: wrap;
    //  padding: 20px;
    box-sizing: border-box;
    //  flex: 1 0 300px;
    margin: 0 auto;
    width: 100% !important;
}

.grid-sizer,
.grid-item {
    width: 24%;
    margin-bottom: 1%;
}

.gutter-sizer {
    width: 1%;
}

@media (max-width: 1200px) {
    .grid-sizer,
    .grid-item {
        width: 32%;
    }
}

@media (max-width: 991px) {
    .grid-sizer,
    .grid-item {
        width: 49%;
    }
}

@media (max-width: 500px) {
    .grid-sizer,
    .grid-item {
        width: 99%;
        margin-bottom: 4%;
    }
}

.card {
    position: relative;
    top: 0;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    margin-left: 0.5%;
    height: 360px;
    // min-width: 360px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}

.card .image {
    overflow: hidden;
    max-width: 100%;
    border: none;
    box-shadow: none;
    width: 225px;
    margin: auto;
}

.card .image img {
    max-width: 200px;
    border: none;
    box-shadow: none;
    box-sizing: content-box;
    object-fit: contain;
    background-color: white;
    padding: 10px;
    height: auto;
    margin: auto;
    transition: 0.5s;
}

.card:hover .image img {
    opacity: 0.5;
    transform: translateX(30%);
}

.card .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    background: #ccccc8;
    transition: 0.5s;
    transform-origin: left;
    transform: perspective(2000px) rotateY(-90deg);
}

.card:hover .details {
    transform: perspective(2000px) rotateY(0deg);
}

.card .details .center {
    padding: 20px;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 5px;
    height: 320px;
    width: -webkit-fill-available;
}

.card .details .center h1 {
    margin: 0;
    padding: 0;
    color: #ff3636;
    line-height: 20px;
    font-size: 20px;
    text-transform: uppercase;
}

.card .details .center p {
    margin: 10px 0;
    padding: 0;
    color: #262626;
    font-size: 16px;
    overflow: hidden;
    line-height: 20px;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: block;
    display: -webkit-box;
}

.input-search-zone-partner {
    margin-bottom: 0;
    width: 320px;
    height: 10px;
}

.searchAndGallerie {
    background-color: white;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    height: 74px;
    margin-bottom: 25px;
    width: 100%;
}

@media only screen and (min-width: 320px) and (max-width: 426px) {
    .searchAndGallerie {
        width: 100%;
    }
}
