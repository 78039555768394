$circle-w: 4rem;
$circle-bg: rgba(255, 255, 255, 0);
// $arrow-bg:#55544F;
$arrow-bg: rgb(255, 255, 255);
$bezier: cubic-bezier(0.77, 0, 0.175, 1);
$bezier-bounce: cubic-bezier(0.87, -0.41, 0.19, 1.44);
$anim-timing: 3s;

@media (min-width: 991px) {
    .scroll-down-sm {
        display: none;
    }
}
@media (max-height: 650px) {
    .scroll-down-sm {
        position: absolute !important;
        bottom: -15% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}
.scroll-down-sm {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 9999999;
    opacity: 1;
}
.ico {
    margin: 0 auto;
    text-align: center;
    width: $circle-w;
    height: $circle-w;
    position: relative;
    cursor: pointer !important;
    top: 50%;
    transform: translateY(-50%);
    filter: url("#goo");
    .circle {
        background: $circle-bg;
        border-radius: 50%;
        display: inline-block;
        height: $circle-w;
        width: $circle-w;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        transform-origin: 0%;

        &.circle-top {
            height: calc($circle-w / 3);
            width: calc($circle-w / 2.4);
            animation: blob-1-anim $anim-timing $bezier infinite;
            z-index: 0;

            top: 0;
        }
        &.circle-bottom {
            height: calc($circle-w / 3);
            width: calc($circle-w / 2.4);
            animation: blob-2-anim $anim-timing $bezier infinite;
            z-index: 9;

            bottom: 0px;
        }
    }

    .st-arrow {
        fill: $arrow-bg;
        animation: scrolly $anim-timing $bezier forwards infinite;
        perspective: 9000px;
        transform: translateZ(0);
        transform-origin: bottom;
    }
}

.svg {
    z-index: 9;
    position: relative;
}

@keyframes blob-1-anim {
    0% {
        transform: translateX(-50%) translateY(0);
    }
    14% {
        transform: translateX(-50%) translateY(-8px);
    }
    24% {
        transform: translateX(-50%) translateY(0);
    }
    100% {
        transform: translateX(-50%) translateY(0);
    }
}

@keyframes blob-2-anim {
    0% {
        //transform: scale(1) translate(-50%,-150%);
        transform: scale(1) translate(-50%, 10px);
    }
    30% {
        //transform: scale(1) translate(-50%,-50%);
        transform: scale(1) translate(-50%, 10px);
    }
    70% {
        //transform: scale(1) translate(-50%,-50%);
        transform: scale(1) translate(-50%, 10px);
    }
    95% {
        //transform: scale(1) translate(-50%,-50%);
        transform: scale(1) translate(-50%, 26px);
    }
    100% {
        //transform: scaleX(1.5) translate(-50%,140%);
        transform: scale(1) translate(-50%, 10px);
    }
}

@keyframes scrolly {
    0% {
        transform: translate3d(0, -150%, 0) rotateX(90deg) scale(0.5)
            skewX(3deg);
    }
    30% {
        transform: translate3d(0, 0, 0) rotateX(0deg) scale(1) skewX(0deg);
    }
    70% {
        transform: translate3d(0, 0, 0) rotateX(0deg) scale(1) skewX(0deg);
    }
    95% {
        transform: translate3d(0, 50%, 0) rotateX(-90deg) scale(0.5)
            skewX(-3deg);
    }
    100% {
        transform: translate3d(0, 50%, 0) rotateX(-90deg) scale(0.5)
            skewX(-3deg);
    }
}
