.prg-card {
    // flex: 1 0 21%;
    background-color: $color-white-200;
    padding: 1rem;
    margin: 1rem;
    color: $font-primary;
    transition: all 0.3s ease;
    // width: 430px;
    height: auto !important;
    h6 {
        font-weight: 500;
        font-size: 1.5625rem;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 28px;
    }
    h5 {
        padding-top: 1rem;
        font-size: 2rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 92px;
    }
    p {
        font-weight: 300;
        // padding-top: 2rem;
        // padding-left: 0;
        font-size: 1.3rem !important;
        line-height: 1.6rem;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 80px;
        @include media-breakpoint-down(md) {
            height: 68px;
        }
    }
    img {
        width: 100%;
        height: 232px;
        object-fit: cover;
        margin-top: 0 !important;
    }

    a {
        color: $font-primary !important;
    }
    // @include max-breakpoint(1600px) {
    //     flex: 1 0 29%;
    //     max-width: calc(100vw * 0.29);
    // }
    // @include max-breakpoint(900px) {
    //     flex: 1 0 45%;
    //     max-width: calc(100vw * 0.45);
    //     p {
    //         margin-bottom: 1rem !important;
    //     }
    // }
    // @include max-breakpoint(500px) {
    //     flex: 1 0 100%;
    //     max-width: 100%;
    //     p {
    //         margin-top: 1rem;
    //     }
    // }
}
// .prg-card:first-child{
//     margin-left: 0px;

// }
// .prg-card:last-child{
//     margin-right: 0px;
// }
