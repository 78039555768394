.body-theme-purple{
background: $color-purple;

}

.body-theme-blue{

    background:$color-blue;

}
.body-theme-orange{
    background: $color-orange;


}
.body-theme-light-orange{
    background: $color-orange-light;


}
.body-theme-white{
    background: $color-white;


}
.body-theme-grey{
    background: $color-grey;


}
.body-theme-green{
    background:$color-green;


}



